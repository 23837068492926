import React from "react";
import styled from "styled-components";
import PricingPlan from "./PricingPlan";
import { Swiper, SwiperSlide } from "swiper/react";

const PricingContainer = styled.div`
h4{
  color:white;
}
  padding: 0 32px;

  @media (min-width: 640px) {
    text-align: center;
  }
`;


const plans = [
  {
    title: "11 years and younger",
    price: 229,
    features: [
      "2x Cleaning per year",
      "One emergency exam visit",
      "Exams",
      "X-Rays",
      "1 in-house whitening",
      "20% off treatment",
    ],
  },
  {
    title: "12 years and older",
    price: 379,
    features: [
      "2x Cleaning per year",
      "One emergency exam visit",
      "Exams",
      "X-Rays",
      "1 in-house whitening",
      "20% off treatment",
    ],
  },
];

export default function PricingSection() {


  return (
    <div
      style={{
        background: "linear-gradient(138.37deg, #2FC6E7 0%, #3371F2 153.28%)",
        paddingTop: "32px",
      }}
    >
      <PricingContainer>
        <p style={{ fontWeight: 700, color: "white", letterSpacing: "0.1em" }}>
          PROMOTION PLANS
        </p>
        <h4 style={{ marginTop: "24px" }}>In-House Membership</h4>
      </PricingContainer>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={32}
        centerInsufficientSlides={true}
        style={{ padding: "32px 32px 64px" }}
      >
        {
          plans.map((plan, i) => (
            <SwiperSlide
              style={{ width: "333px", maxWidth: "98%", height: "auto" }}
              key={i}
            >
              <PricingPlan {...plan}  />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}
